#header {
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  z-index: 100;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;

  img {
    max-height: 65px;
  }
}

.calculate-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    width: 100%;
    flex-shrink: 0;
    position: relative;
    z-index: 99;
  }

  .bg-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .d-block {
      display: block;
    }

    .d-none {
      display: none;
    }

    img {
      position: absolute;
    }

    .shopping-lady {
      top: 50%;
      right: 5%;
      height: 75%;
      opacity: 0.3;
    }

    .shopping-cart {
      width: 15%;
      top: 5%;
      left: 5%;
      transform: rotate(25deg);
      opacity: 0.3;
    }
  }
}

.calculate {
  padding: 30px 0;

  .progress {
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 15px 0;
    background: #fff;
    box-shadow: rgba(0, 0, 0, .1) 0 4px 12px;
    border-radius: 0;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 20%;
      cursor: pointer;
      position: relative;

      .icon-wrapper {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #00a97e;
        margin-bottom: 20px;

        .icon {
          width: 16px;
          max-height: 24px;
          fill: #ffffff;

        }
      }

      h5 {
        color: #00a97e;
        font-size: 16px;
      }

      &.disabled {
        .icon-wrapper {
          background: #8b9895;
        }

        h5 {
          color: #b0aeae;
        }

        &:after {
          background: #8b9895;
        }
      }


      &:after {
        content: "";
        position: absolute;
        width: 50%;
        height: 2px;
        background: #00a97e;
        right: 0;
        top: 50%;
        transform: translate(50%, calc(-50% - 20px));
      }

      &:last-child {
        &:after {
          content: none;
        }
      }


    }
  }

  .content {
    width: 100%;
    height: calc(100vh - 381px);
    margin-top: 30px;
    box-shadow: rgba(0, 0, 0, .1) 0 4px 12px;
    display: flex;

    .left {
      width: 50%;
      height: 100%;
      background: #67d3b8;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 300px;
      }
    }

    .right {
      width: 50%;
      height: 100%;
      position: relative;
      padding: 30px 30px 0;
      background-color: white;

      .step-container {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 110px;
        height: 100%;
        overflow-y: auto;
        margin-right: -25px;
        padding-right: 25px;
      }

      .form-button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        gap: 32px;
        height: 90px;
        bottom: 0;
        left: 0;
        background: #ffffff;
        box-shadow: rgba(0, 0, 0, .1) 0 -1px 6px;
      }
    }
  }
}

.sum {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;


  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 48px;
    font-weight: bold;
  }


}

.general-info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .general-info {
    width: 100%;
    font-size: 14px;


    .bold {
      font-size: 18px;
      padding-right: 8px;
    }
  }
}


@media only screen and (max-width: 992px) {
  .calculate {
    .progress {
      .item {
        .icon-wrapper {
          width: 28px;
          height: 28px;

          .icon {
            width: 13px;
          }
        }

        h5 {
          font-size: 12px;
        }
      }
    }

    .content {
      flex-direction: column;
      height: unset;

      .left, .right {
        width: 100%;
      }

      .left {
        padding: 15px;

        img {
          max-width: 200px;
        }
      }

      .right {
        padding: 45px 15px;

        .form-button {
          gap: unset;

          button{
            margin: 10px;
          }
        }
      }
    }
  }
}

.main-banner {
  max-height: 700px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: scaleIMG 8s infinite;
  }

  .content {
    padding: 150px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text {
    width: 50%;
    height: 100%;
    background: rgba(0, 169, 126, .75);
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;

    h2 {
      font-weight: 700;
      font-size: 32px;
      margin-bottom: 10px;

      &:nth-child(1) {
        animation: zoom .75s forwards;
      }

      &:nth-child(2) {
        opacity: 0;
        animation: bounceIn .75s forwards;
        animation-delay: .75s;
        margin-bottom: 0;
      }
    }

    p {
      opacity: 0;
      animation: slideInBottom .75s forwards;
      animation-delay: 1.5s;
    }

    .line {
      height: 1px;
      width: 100%;
      margin: 30px auto;
      background: #ffffff;
    }
  }
}


.missions {
  margin: 100px 0;
  display: flex;
  align-items: center;

  .box {
    width: calc(100% / 3 - 30px);
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 90px;
      margin-bottom: 30px;
    }

    h4 {
      font-weight: 700;
      font-style: normal;
      font-size: 18px;
      margin-bottom: 15px;
      text-align: center;
    }

    p {
      text-align: center;
      color: rgba(0, 0, 0, .6);
      font-size: 16px;
      line-height: 30px;
    }
  }
}

.subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: #00a97e;
    padding-bottom: 15px;
    font-size: 36px;
  }

  .line {
    width: 400px;
    margin: 20px 0;
    height: 2px;
    background: rgba(0, 169, 126, 1);
    display: flex;
    position: relative;

    img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 30px;
      transform: translate(100%, 0px);

      &:nth-child(2) {
        right: auto;
        left: 0;
        transform: translate(-100%, 0px) scaleX(-1);
      }
    }
  }
}


.calculate-section {
  background: #00a97e;
  padding: 35px 0;
  text-align: center;
  color: #fff;

  h2 {
    padding-bottom: 15px;
    font-size: 36px;
  }

  .line {
    width: 400px;
    margin: 20px auto;
    height: 2px;
    background: #fff;
    display: flex;
  }

  p {
    text-align: center;
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 30px;
  }
}

@keyframes scaleIMG {
  0% {
    transform: scale(1)
  }

  50% {
    transform: scale(1.1)
  }

  100% {
    transform: scale(1)
  }
}


@keyframes zoom {
  from {
    opacity: .5;
    transform: scale3d(0.3, 0.3, 0.3)
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1)
  }
}


@keyframes slideInBottom {
  from {
    opacity: .5;
    transform: translateY(100%)
  }

  to {
    opacity: 1;
    transform: translateY(0)
  }
}


@keyframes bounceIn {

  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}


@media only screen and (max-width: 992px) {
  .main-banner {
    max-height: 450px;

    img {
      width: auto;
    }

    .content {
      padding: 50px 30px;
    }

    .text {
      max-width: 500px;
      width: 100%;
      padding: 30px;

      h2 {
        font-size: 22px;
      }

      p {
        font-size: 13px;
      }
    }
  }

  .subtitle {
    h2 {
      font-size: 28px;
      text-align: center;
    }

    p {
      text-align: center;
    }

    .line {
      width: 100%;
      max-width: 300px;
    }
  }

  .missions {
    flex-wrap: wrap;
    margin: 50px 0;

    .box {
      width: 100%;
      margin-bottom: 20px;

      p {
        font-size: 12px;
      }
    }
  }

  .calculate-section {
    .line {
      width: 300px;
    }

    p {
      font-size: 13px;
    }
  }
}

/* You can add global styles to this file, and also import other style files */

@import './assets/fonts/Montserrat/stylesheet.css';
@import '~bootstrap/scss/bootstrap';


* {
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background: #00a97e;
}


body {
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  height: 100%;
  width: 100%;
  background: #efefef;
  padding: 80px 0 0;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}


p,
label {
  margin: 0;
}

button:focus {
  outline: 0;
  box-shadow: none;
}


.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}


.custom-button {
  cursor: pointer;
  background: transparent;
  width: 180px;
  height: 50px;
  border-radius: 25px;
  border: 2px solid #ffffff;
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all .3s;

  &:hover {
    background: #ffffff;
    color: #00a97e;
  }

  &.fill {
    border: 2px solid #00a97e;
    background: #00a97e;

    &:hover {
      background: #ffffff;
      color: #00a97e;
    }

    &:disabled {
      cursor: inherit;
      border: 2px solid #67d3b8;
      background: #67d3b8;

      &:hover {
        color: #fff;
      }
    }
  }
}


.custom-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  padding: 0 10px;
  cursor: pointer;
  color: red;
  font-weight: bold;
  font-size: 11px;

  &.half {
    width: 50%;
  }

  label {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 15px;
    color: #212529;
  }

  input,
  select,
  textarea {
    border: 1px solid rgba(139, 152, 149, .3);
    height: 48px;
    padding: 0 15px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}


@media only screen and (max-width: 992px) {
  .custom-input {
    label {
      font-size: 12px;
    }

    input, select, textarea {
      font-size: 12px;
      height: 42px;
    }
  }

  .custom-button {
    width: 140px;
  }
}
